import React from 'react';
import PropTypes from 'prop-types';

import Container from 'molecules/Container';
import Hero from 'organisms/Hero';
import RefTagger from 'molecules/RefTagger'
import Map from 'organisms/Map';

import settings from 'data/globals/settings';

import mountainSkies from '~static/images/mountain-skies.jpg';
import 'organisms/PageContent/PageContent.scss';

/**
 * Contact Template.
 */
const Contact = ( { pageContext: page } ) => {

  const { content } = page;

  // /* ------ = Output. = --------------------------------------------------------------------- */
  return(

    <div className={ `Default contact`}>

      <section>
        <Hero data={ {
          bg: {
            src: mountainSkies,
          },
        } }
        />
      </section>

      <section>
        <div className="PageContent">
          <Container>

            <div className="PageContent__hding f-page-title">
              <h1 className="txt">
                How to Contact Us
              </h1>
            </div>

            <section style={ {
              marginTop: '-2rem',
              textAlign: 'center',
              lineHeight: '1.3',
              paddingTop: '1.5rem',
              paddingBottom: '1.5rem',
              borderBottom: 'solid 1px var( --gray-border )',
            } }
            >
              <h2 style={ {
                fontFamily: 'var( --f-serif )',
                color: 'var( --gold )',
                marginBottom: '0.5em',
              } }
              >
                Mailing Address
              </h2>
              <h3>Red Mills Baptist</h3>
              <div>{ settings.address.street }</div>
              <div>{ `${settings.address.city}, ${settings.address.state} ${settings.address.zip}` }</div>
            </section>

            <section style={ {
              textAlign: 'center',
              lineHeight: '2',
              paddingTop: '1.5rem',
              paddingBottom: '1.5rem',
              borderBottom: 'solid 1px var( --gray-border )',
            } }
            >

              <div>
                <span>Phone: </span>
                <a
                href={ `tel:${settings.primary_phone}` }
                style={ {
                  color: 'var( --blue )',
                  textDecoration: 'underline',
                } }
                >
                  { settings.primary_phone }
                </a>
              </div>

              <div>
                <span>Email: </span>
                <a
                href={ `email:${settings.primary_email}` }
                style={ {
                  color: 'var( --blue )',
                  textDecoration: 'underline',
                } }
                >
                  { settings.primary_email }
                </a>
              </div>


            </section>

            <section style={ {
              textAlign: 'center',
              lineHeight: '1.5',
              paddingTop: '1.5rem',
              paddingBottom: '4rem',
              borderBottom: 'solid 1px var( --gray-border )',
            } }
            >

              <h2 style={ {
                fontFamily: 'var( --f-serif )',
                color: 'var( --gold )',
                marginBottom: '0.5em',
              } }
              >
              Directions
              </h2>

              <a
              href="https://www.google.com/maps/place/Red+Mills+Baptist+Church/@41.3725194,-73.7685504,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2b4be1d0ef365:0xb908aeaa3a539cbf!8m2!3d41.3725154!4d-73.7663617"
              target="_blank"
              style={ {
                color: 'var( --red )',
                fontWeight: '600',
                textDecoration: 'underline',
                fontSize: '18px',
              } }
              >
                View on Google Maps
              </a>


            </section>



          </Container>
        </div>

      </section>

    </div>

  )

};
export default Contact;