import classnames from 'classnames';
import each from 'lodash/each';
import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from  'google-map-react';
import { useSetState } from 'react-use';
import Icon from 'molecules/Icon';

import './Map.scss';


const Marker = React.memo( MarkerComponent );


/**
 * Display a google map.
 */
const proptypes = {
  listings: PropTypes.array,
  offices: PropTypes.array,
  size: PropTypes.string,
  withPopups: PropTypes.bool,
}
const Map = React.memo( p => {


  /* ------ = Output. = --------------------------------------------------------------------- */
  const className= classnames( 'Map' );

  return(

    <div className={ className }>
      <GoogleMapReact
      bootstrapURLKeys={ {
        key: process.env.GMAPS_API,
      } }
      defaultCenter={ {
        lat: 41.3725194,
        lng: -73.7685504,
      } }
      defaultZoom={ 11 }
      >

        <Marker
        key={ 'home' }
        type="listing"
        lat="41.3725194"
        lng="-73.7685504"
        />

      </GoogleMapReact >

    </div>

  )
} );
Map.propTypes = proptypes;

export default Map;

/* ------ = Subcomponents. = --------------------------------------------------------------------- */


/**
 * Marker component.
 */
function MarkerComponent( { children, lat, lng, type, onClick } ) {

  return(

    <div
    className={ `Map__marker is-${type}` }
    lat={ lat }
    lng={ lng }
    onClick={ onClick }
    >
      { children }
    </div>

  )

}
