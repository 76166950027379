import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { default as AntIcon } from 'antd/lib/icon';

import './Icon.scss';

/**
 * Icon.
 */
const proptypes = {

}
const Icon = ( { className: customClass, ...p } ) => {

  const className = classnames(
    'Icon',
    customClass
  );


  return(

    <>
      { p.type

        ? <AntIcon className={ className } { ...p } />

      : <i className={ className } />

      }
    </>

  );

};
Icon.propTypes = proptypes;

export default Icon;
